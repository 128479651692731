:root, .default-theme {
  --font-style-primary: nunito;
  --font-style-secondary: nunito;

  --background-primary: #FFFFFF;
  --background-secondary: #F4F4F4;
  --desktop-background-primary: #FFFFFF;

  --primary-color: #fdef23;

  --iconButtonText-primary: #eb3600;
  --iconTextButton-primary:#eb3600;
  --iconButtonText-secondary: #eb3600;
  --iconButtonText-tertiary: #eb3600;
  --iconTextButton-tertiary: #eb3600;
  --iconButtonText-quaternary: #000000;
  --iconButtonText-quinary:#eb3600;

  --card-color-primary: #FFFFFF;
  --card-border-primary: #b1b1b1;
  --card-color-secondary: #FFFFFF;
  --card-color-tertiary : #F6F6F6;
  --card-color-quaternary : #F4F4F4;
  --card-color-quinary : #F4F4F4;
  --dialog-color-primary: #FFFFFF;
  --dialog-color-secondary:#FFFFFF;

  --inputbox-primary: #FFFFFF;
  --inputbox-outline-primary: #eb3600;
  --inputbox-outline-secondary: #B1b1b1b1;
  --inputbox-text-primary :#000000;

  --text-primary: #000000;
  --text-secondary: #000000;
  --text-tertiary: #eb3600;
  --text-quaternary : #000000;
  --text-font-weight-primary: bold;

  //general button + text
  --button-primary: #eb3600;
  --button-text-primary: #FFFFFF;

  --button-secondary: #eb3600;
  --button-text-secondary: #FFFFFF;

  --button-tertiary: #fdef23;
  --button-text-tertiary: #eb3600;

  --store-modif-plus-button-primary: #fdef23;
  --store-modif-plus-icon-primary: #000000;
  --store-modif-minus-icon-primary: #000000;

  --backbutton: #8E8E8E;

  //store
  --order-type-primary: #fdef23;
  --order-type-secondary: #eb3600;
  --order-type-text-primary: #eb3600;
  --store-price-primary: #eb3600;
  --item-card-border-primary: #E2E2E2;
  --date-time-border-primary: #B1B1B1;

  --selected-primary: #fdef23;
  --selected-secondary: #fdef23;
  --selected-tertiary: #fdef23;
  --selected-quaternary: #eb3600;
  --outline-primary: #FBDCD2;
  --odr-selected-primary: #eb3600;

  //cart
  --cart-card-primary: #fdef23;
  --order-item-indicator: #eb3600;
  --cart-price-primary: #eb3600;
  --cart-order-info-background: #F6F6F6;;

  //dine-in
  --selected-orderType: #eb3600;

  --icon-background : rgba(0, 0, 0, 5%);

  --border-outline: #EB360026;

  --cart-header : #f4f4f4;
  --cart-outline-primary: transparent;
  --order-item-icon-indicator: #FFFFFF;
  --cart-text-primary: #000000;
  --unselected-cart-outline: #f4f4f4;

  --selected-menu-primary: rgba(235, 54, 0, 0.06);

  --payment-background-primary: #F4F4F4;

  --dropdownMenu-currentTab-primary: #000000;
  --current-section-tag-primary: #eb3600;

  --item-card-outline-primary: #2660414D;

  --item-card-outline-primary: #FBDCD2;

  --order-summary-header-primary: #fdef23;
  --order-amount-card: rgba(253, 239, 35, 0.1);

  --store-info-detail-primary: #007AFF;
  --disable-section : #f4f4f4;
  --membership-card-backrgound: url('../assets/membership_dashboard.svg');
  --membership-card-border: #F6D4A1;
  --membership-card-box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  --scrollup-icon : #eb3600;
}
