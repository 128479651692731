@import url('https://use.typekit.net/hns6liy.css');

@import "fonts/odaring-font.css";
@import "ngx-sharebuttons/themes/circles/circles-dark-theme";

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';

@import 'src/themes/default-theme.scss';
@import 'src/themes/oriental-theme.scss';
@import 'src/themes/lotuscar-theme.scss';

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-style-primary);
  font-size: 16px;
  background: var(--background-primary) !important;
  height: 100%;
  width: 100%;
  overflow: auto !important;

  div, button, h1, h2, h3, h4, h5, h6, p, label {
    font-family: var(--font-style-primary);
    font-size: 16px;
  }
}

.highlight {
  background-color: rgb(253, 239, 35, 0.3);
  font-weight: bold;
}

.heading-1 {
  font-size: 60px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-2 {
  font-size: 35px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-3 {
  font-size: 35px;
  font-weight: 800;
  color: #3B3B3B;
}

.heading-4 {
  font-size: 30px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-5 {
  font-size: 25px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-6 {
  font-size: 20px;
  font-weight: normal;
  color: var(--text-primary);
}

.heading-7 {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-8 {
  font-size: 16px;
  font-weight: normal;
  color: #B1B1B1;

  b {
    color: var(--text-primary);
  }
}

.heading-9 {
  font-size: 16px;
  font-weight: normal;
  color: var(--text-primary);
}

.heading-10 {
  font-size: 16px;
  font-weight: normal;
  color: var(--text-primary);
}

.heading-11 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-12 {
  font-size: 13px;
  font-weight: normal;
  color: var(--text-primary);
}

.heading-13 {
  font-size: 13px;
  font-weight: normal;
  color: #B1B1B1;
}

.heading-14 {
  font-size: 17px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-15 {
  font-size: 16px;
  font-weight: normal;
  color: var(--text-primary)
}

.heading-16 {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-primary);
}

.heading-17 {
  font-size: 13px;
  font-weight: normal;
  color: var(--text-primary)
}

.error-text {
  color: #FF0000;
  font-weight: bold;
  font-size: 13px;
  color: #FF0000;
  padding-left: 16px;

  i {
    font-size: 15px;
  }
}

.invalid-feeback {
  font-size: 13px;
  font-weight: bold;
  color: #FF0000;
  padding-left: 16px;
  margin-top: 8px;

  i {
    font-size: 15px;
    color: #FF0000;
    margin-right: 2px;
    vertical-align: text-bottom;
  }
}

input {
  font-family: var(--font-style-primary) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: var(--text-secondary) !important;
}

input::placeholder {
  font-family: var(--font-style-primary) !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: #B1B1B1 !important;
}

.p-inputtext::placeholder {
  font-family: var(--font-style-primary) !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: #B1B1B1 !important;
}

.p-inputtext, .form-control {
  margin: 0 !important;
  outline: none !important;
  border: 0.5px solid #B1B1B1 !important;
  border-radius: 25px !important;
  width: 100% !important;
  height: 50px !important;
  font-family: var(--font-style-primary) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  background-color: var(--inputbox-primary) !important;
  color: var(--inputbox-text-primary) !important;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    border: 0.5px solid var(--inputbox-outline-primary) !important;
    box-shadow: none !important;
  }
}

button {
  border: none;
  outline: none !important;
  margin: 0px;
  padding: 0px;
  background-color: transparent;
  font-family: var(--font-style-primary) !important;
  font-size: 16px;
  cursor: pointer;
}

i {
  font-size: 2rem;
}

.p-overflow-hidden {
  overflow: hidden !important;

  .p-datepicker-mask {
    display: none;
  }
}

.check-box-content {
  margin-bottom: 21px;

  /* Customize the label (the container) */
  .checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 0;
    line-height: 20px;
    color: #b1b1b1;

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: #8CD600;
      border: 1px solid #8CD600;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: -5.5px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: white;
      border-radius: 50%;
      border: 1px solid #B1B1B1;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 50%;
      top: 44%;
      width: 5.72px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
    }

    input:checked ~ span {
      color: var(--text-primary);
    }

    span {
      font-size: 16px;
      line-height: 18px;
      color: #B1B1B1;
    }
  }
}

@media (min-width: 992px) {
  .hidden-lg {
    display: none !important;
  }
}

// Media Query Classes
@media (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }

  .check-box-content {
    margin-bottom: 20px;

    .checkbox-container {
      font-size: 13px;
      line-height: 18px;
      padding-left: 25px;

        .checkmark {
          top: -2px;
          height: 20px;
          width: 20px;
          margin-right: 5px;
        }

        span {
          font-size: 13px;
          line-height: 18px;
        }
    }
  }

  .other-store-dialog {
    .p-dialog-mask {
      align-items: flex-end;

      .p-dialog {
        max-height: 50%;
        height: 50%;
        width: 100% !important;

        .p-dialog-header {
          padding: 18px 15px 0 15px;
          border-radius: 20px 20px 0 0 !important;

          .p-dialog-header-icon {
            span {
              font-size: 10px;
            }
          }
        }

        .p-dialog-content {
          border-radius: 0 !important;
        }
      }
    }
  }
}

//phone number -country code
.iti {
  display: block !important;
  margin-bottom: 0.5rem;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open + input {
  font-size: 16px;

}

.disabled-color {
  color: #B1B1B1 !important;
  font-weight: 800;

  ngx-intl-tel-input .iti__selected-flag:hover {
    background-color: unset !important;
  }

  ngx-intl-tel-input input{
    color: var(--text-secondary) !important;
  }

  ngx-intl-tel-input input:hover {
    box-shadow: unset !important;
  }
}

ngx-intl-tel-input .iti .iti--allow-dropdown {
  border-radius: 25px;
}

ngx-intl-tel-input .iti__flag-container {
  border-radius: 25px;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #B1B1B1;
  border-radius: 0;
  padding: 5px 10px;
  color: var(--text-secondary) !important;
}

.iti .search-container input:focus {
  outline: none;
}

ngx-intl-tel-input .iti__selected-flag:hover {
  margin-right: 10px;
}

ngx-intl-tel-input input {
  height: 50px;
  width: 100%;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #B1B1B1;
  border-radius: 25px;
  font: normal normal bold 16px/22px var(--font-style-primary) !important;
  color: var(--text-secondary);
}


ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 0.5px solid #EB3600;
}

ngx-intl-tel-input input:hover {
  border-color: #EB3600;
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #EB3600;
  border-radius: 25px;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  font-family: var(--font-style-primary);
  font-size: 16px;
  font-weight: bold;
  color: #B1B1B1;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.iti .iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0 0 18px;
}

.iti__selected-flag {
  border-radius: 25px 0px 0px 25px !important;
}

.iti__arrow {
  border-right: 0px !important;
  border-top: 0px !important;
  border-left: 1px solid #B1B1B1 !important;
  height: 29px !important;
  margin-left: 13px !important;
}

@media only screen and (max-width: 480px) {
  .iti .iti__country-list {
    width: 108.3vw;
  }
}
//phone number -country code

@media (max-width: 991px) {

  body {
    font-size: 13px;
    div, button, h1, h2, h3, h4, h5, h6, p, label {
      font-size: 13px;
      line-height: 1.5;
    }
  }

  ngx-intl-tel-input input {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-style: solid;
    border-width: 0.5px;
    border-color: #B1B1B1;
    border-radius: 25px;
    color: var(--text-secondary) !important;
    font: normal normal bold 13px var(--font-style-primary) !important;
  }

  .iti .selected-dial-code {
    margin-left: 6px;
    font-size: 13px;
    color: var(--text-secondary) !important;
  }

  ngx-intl-tel-input input::-webkit-input-placeholder {
    font-family: var(--font-style-primary);
    font-size: 13px;
    font-weight: bold;
    color: #B1B1B1;
  }

  .invalid-feeback {
    font-size: 10px;
    font-weight: bold;
    color: #FF0000;

    i {
      font-size: 0.85rem;
      color: #FF0000;
      vertical-align: middle;
      margin-right: 2px;
    }
  }

  input {
    font-family: var(--font-style-primary);
    font-size: 13px;
    font-weight: bold;
    color: var(--text-secondary) !important;
  }

  .p-inputtext::placeholder {
    font-size: 13px !important;
  }

  .p-inputtext, .form-control {
    height: 40px !important;
    font-size: 13px !important;
  }

  input::placeholder {
    font-family: var(--font-style-primary) !important;
    font-size: 16px !important;
    font-weight: normal !important;
    color: #B1B1B1 !important;
  }

  input::placeholder {
    font-size: 13px !important;
  }

  button {
    font-size: 13px;
  }

  .heading-5 {
    font-size: 13px;
    font-weight: bold;
    color: var(--text-primary);
  }

  .heading-8 {
    font-size: 13px;
    font-weight: normal;
    color: #B1B1B1;
  }

  .heading-9 {
    font-size: 13px;
    font-weight: normal;
    color: var(--text-primary);
  }

  .heading-10 {
    font-size: 13px;
    font-weight: normal;
    color: var(--text-primary);
  }

  .heading-11 {
    font-size: 13px;
    font-weight: bold;
    color: var(--text-primary);
  }

  .heading-16 {
    font-size: 11px;
    font-weight: bold;
    color: var(--text-primary);
  }

  .badge-white-mobile {
    color: var(--iconTextButton-primary) !important;
    background-color: #FFFFFF !important;
    position: absolute;
    top: 5px;
    right: 6px;
    width: 12px !important;
    height: 12px !important;
    min-width: auto !important;
    font-size: 8px !important;
    font-family: var(--font-style-primary) !important;
    padding: 0 !important;
    line-height: 14px !important;
  }

  .menu-tag-img {
    width: 20px !important;
  }
}

.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2[_ngcontent-snw-c52]
input[_ngcontent-snw-c52],
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3[_ngcontent-snw-c52]
input[_ngcontent-snw-c52],
.iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4[_ngcontent-snw-c52]
input[_ngcontent-snw-c52],
.iti.separate-dial-code[_ngcontent-snw-c52] input[_ngcontent-snw-c52] {
  padding-left: 85px !important;
}

ngx-intl-tel-input input[disabled] {
  background-color: white;
}

ngx-intl-tel-input{
  div{
    color: var(--text-secondary);
  }
}

.disabled-button {
  background-color: #F4F4F4 !important;
  color: #B1B1B1 !important;
  cursor: not-allowed !important;
}

.pac-container {
  background-color: var(--dialog-color-secondary);
  position: absolute !important;
  z-index: 1000;
  font-family: Arial,sans-serif;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: scroll;
  border-radius: 20px !important;
  margin-top: 8px !important;
  width: -webkit-fill-available;
  height: 200px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  .pac-item {
    font-size: 16px !important;
    margin: 10px;
    border: none !important;
    border-radius: 20px;
    padding: 3px 5px 0px 25px;
    cursor: pointer;
    color: var(--inputbox-text-primary);

    .pac-icon {
      display: none !important;
      padding: 0 30px !important;
    }

    .pac-item-query {
      font-size: 16px;
      display: block;
      margin-bottom: -8px;
      padding-bottom: 2px;
    }
  }

  .pac-item:hover {
    background-color: rgba(235,54,0, 0.06);
  }
}

.pac-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.hdpi.pac-logo:after {
  display: none !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-dropdown-panel {
  box-shadow: none !important;
  border: solid !important;
  border-color: rgba(177,177,177, 0.5) !important;
  border-width: 0.5px !important;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.active-link {
  i::before {
    color: var(--iconTextButton-primary);
  }

  i::after {
    color: var(--iconTextButton-primary);
  }

  .nav__text {
    color: var(--iconTextButton-primary) !important;
    font-weight: bold !important;
  }
}

.oda-d-edit {
  color: var(--iconTextButton-primary);
}

.oda-d-trash {
  color: #B1B1B1;
}

.mobile-header {
  padding-top: 3%;
  position: relative;

  .sub-header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-secondary);
  }

  .back-btn {
    position: absolute;
    left: 16px;
  }

  h1 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: bold;
  }

  h2{
    font-size: 13px;
  }

  h1, h2 {
    text-align: center;
    color: var(--text-secondary);
  }

  img {
    width: auto;
    height: 120px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    margin-bottom: 10px;
  }
}

.general-overlay {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: black;
  height: 100%;
  width: 100%;
  opacity: 0.70;
  cursor: pointer;
}

.other-store-dialog {
  .p-dialog-mask {
    .p-dialog {
      background-color: white;
      width: 600px;

      .p-dialog-header {
        padding: 28px 35px 0 35px;
        border-radius: 30px 30px 0 0;
        justify-content: flex-end;

        .p-dialog-header-icon {
          width: auto;
          height: auto;
        }
      }

      .p-dialog-content {
        display: flex;
        padding: 0;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 0 0 30px 30px;

        app-nearby-store {
          width: 100%;
        }
      }
    }
  }
}

.end-load-text {
  color: #B1B1B1;
  font-size: 13px;
  text-align: center;
  margin-top: 15px;
  width: 100%;
}

.badge-white {
  color: var(--iconTextButton-primary) !important;
  background-color: #FFFFFF !important;
  position: absolute;
  top: 6px;
  right: 11px;
  width: 20px !important;
  height: 20px !important;
  min-width: auto !important;
  font-size: 13px !important;
  font-family: var(--font-style-primary) !important;
  padding: 0 !important;
}

.menu-tag-img {
  width: 24px;
  margin-top: -2px;
}

.menu-item-name {
  vertical-align: middle;
  color: var(--text-secondary);
}

.expiry-padding-top {
  padding-top: 33px;
}
