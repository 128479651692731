.oriental-theme {
  --font-style-primary: poppins;
  --font-style-secondary: poppins;

  --background-primary:#FFFDEE;
  --background-secondary: #F4F4F4;

  --primary-color: #266041;

  --iconButtonText-primary: #C10230;
  --iconTextButton-primary:#C10230;
  --iconButtonText-secondary: #FFFDEE;
  --iconButtonText-tertiary: #C10230;
  --iconTextButton-tertiary: #C10230;
  --iconButtonText-quaternary: #222222;
  --iconButtonText-quinary:#C10230;

  --card-color-primary: #FFFFFF;
  --card-border-primary: #b1b1b1;
  --card-color-secondary: #FFFFFF;
  --card-color-tertiary : #F6F6F6;
  --card-color-quaternary : #F4F4F4;
  --card-color-quinary : #F4F4F4;
  --dialog-color-primary: #FFFFFF;
  --dialog-color-secondary:#FFFFFF;

  --inputbox-primary: #FFFFFF;
  --inputbox-outline-primary: #266041;
  --inputbox-outline-secondary: #B1b1b1b1;
  --inputbox-text-primary :#222222;

  --text-primary: #222222;
  --text-secondary:#222222; //if light: black else :  white
  --text-tertiary: #C10230;
  --text-quaternary : #222222;
  --text-font-weight-primary: bold;

  //general button + text
  --button-primary: #266041;
  --button-text-primary: #FFFFFF;

  --button-secondary: #C10230;
  --button-text-secondary: #FFFFFF;

  --button-tertiary: #C10230;
  --button-text-tertiary: #FFFFFF;

  --store-modif-plus-button-primary: #266041;
  --store-modif-plus-icon-primary: #FFFFFF;
  --store-modif-minus-icon-primary: #266041;

  --backbutton: #8E8E8E;

  //store
  --order-type-primary: #266041;
  --order-type-secondary: #C10230;
  --order-type-text-primary: #FFFDEE;
  --store-price-primary: #C10230;
  --item-card-border-primary: #E2E2E2;
  --date-time-border-primary: #B1B1B1;

  --selected-primary: #2660414D;
  --selected-secondary: #C10230;
  --selected-tertiary: #266041;
  --selected-quaternary: #C10230;
  --outline-primary: #2660414D;

  //cart
  --cart-card-primary: #266041;
  --order-item-indicator: #C10230;
  --order-item-icon-indicator: #FFFDEE;
  --cart-price-primary: #C10230;
  --cart-order-info-background: #F6F6F6;

  //dine-in
  --selected-orderType: #C10230;

  --icon-background : rgba(0, 0, 0, 5%);

  --border-outline: #EB360026;

  --cart-header : #f4f4f4;
  --cart-text-primary: #FFFFFF;
  --cart-outline-primary: transparent;
  --unselected-cart-outline: #f4f4f4;

  --payment-background-primary: #F4F4F4;
  --dropdownMenu-currentTab-primary: #222222;
  --current-section-tag-primary: #C10230;

  --item-card-outline-primary: #2660414D;

  --order-summary-header-primary: #266041;

  --store-info-detail-primary: #007AFF;
  --membership-card-backrgound: #FFF0D7;
  --membership-card-border: #D6C1A5;
  --membership-card-box-shadow: #E3D3B7 0px 0px 0px 1px;
  --scrollup-icon : #266041;
}
