@font-face {
  font-family: "odaring-font";
  src: url("odaring-font.eot?e6wrv6");
  src: url("odaring-font.eot?e6wrv6#iefix") format("embedded-opentype"),
    url("odaring-font.ttf?e6wrv6") format("truetype"),
    url("odaring-font.woff?e6wrv6") format("woff"),
    url("odaring-font.svg?e6wrv6#odaring-font") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

:root {
  --yellow: rgb(253, 239, 35);
  --black: rgb(35, 35, 35);
  --red: rgb(235, 54, 0);
  --blue: rgb(20, 35, 200);
  --green: rgb(20, 168, 32);
  --pink: rgb(224, 144, 144);

  /* replace to apply color scheme */
  --primary: var(--black);
  --secondary: var(--yellow);
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "odaring-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: var(--black);

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* SPECIAL ICONS */
.duotone.oda-d-odaring-logo:after {
  content: "\ea96";
  opacity: 0.5;
}
.duotone.oda-d-odaring-logo:before {
  content: "\ea9a";
}
.duotone.oda-d-odaring-logo-r:after {
  content: "\ea9b";
  color: rgb(235, 54, 0);
  opacity: 0.5;
}
.duotone.oda-d-odaring-logo-r:before {
  content: "\ea9c";
  color: rgb(235, 54, 0);
}
.duotone.oda-d-odaring-logo-w:after {
  content: "\ea9d";
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.duotone.oda-d-odaring-logo-w:before {
  content: "\ea9e";
  color: rgb(255, 255, 255);
}
.oda-visa:before {
  content: "\e96a";
  color: #222357;
}
.oda-mastercard .path1:before {
  content: "\e96b";
  color: rgb(255, 95, 0);
  margin-left: -1em;
}
.color.oda-mastercard:before {
  content: "\e9c3";
  color: rgb(235, 0, 27);
}
.color.oda-mastercard:after {
  content: "\e9c4";
  color: rgb(247, 158, 27);
  margin-left: -1em;
}
.oda-u-odaring-cart .path1:before {
  content: "\e961";
  color: rgb(216, 216, 216);
}
.color.oda-u-odaring-cart:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.color.oda-u-odaring-cart:after {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(177, 177, 177);
}
.oda-u-odaring-cart-x .path1:before {
  content: "\e960";
  color: rgb(216, 216, 216);
}
.oda-u-odaring-cart-x .path2:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.oda-u-odaring-cart-x .path3:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(177, 177, 177);
}
.oda-u-odaring-cart-x .path4:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(177, 177, 177);
}
.oda-u-odaring-cart-x .path5:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

/* COLOR ICONS */
.oda-c-news .path1:before {
  content: "\ea9f";
  color: rgb(235, 54, 0) !important;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-news:before {
  content: "\eaa0";
  color: rgb(235, 54, 0) !important;
}
.color.oda-c-news:after {
  content: "\eaa1";
  color: rgb(253, 239, 35) !important;
}
.color.oda-c-claimed:before {
  content: "\ea8f";
  opacity: 0.1;
}
.color.oda-c-claimed:after {
  content: "\ea90";
  opacity: 0.2;
}
.oda-c-claimed .path1:before {
  content: "\ea91";
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-expired:before {
  content: "\ea93";
  opacity: 0.1;
}
.color.oda-c-expired:after {
  content: "\ea94";
  opacity: 0.2;
}
.oda-c-expired .path1:before {
  content: "\ea95";
  position: absolute;
  top: 0;
  left: 0;
}
.oda-c-served .path1:before {
  content: "\ea97";
  color: rgb(235, 54, 0) !important;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-served:before {
  content: "\ea98";
  color: rgb(235, 54, 0) !important;
}
.color.oda-c-served:after {
  content: "\ea99";
  color: rgb(253, 239, 35) !important;
}
.color.oda-c-used:before {
  content: "\eaa3";
  opacity: 0.1;
}
.color.oda-c-used:after {
  content: "\eaa4";
  opacity: 0.2;
}
.oda-c-used .path1:before {
  content: "\eaa5";
  color: rgb(235, 54, 0) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-delivery-circle:before {
  content: "\e909";
  opacity: 0.1;
}
.color.oda-c-delivery-circle:after {
  content: "\e9cd";
}
.color.oda-c-delivery-circle-y:before {
  content: "\e90b";
  color: var(--red) !important;
}
.color.oda-c-delivery-circle-y:after {
  content: "\e90a";
  color: var(--yellow) !important;
}
.color.oda-c-dinein-circle:before {
  content: "\e90f";
  opacity: 0.1;
}
.color.oda-c-dinein-circle:after {
  content: "\e910";
}
.color.oda-c-dinein-circle-y:before {
  content: "\e911";
  color: var(--yellow) !important;
}
.color.oda-c-dinein-circle-y:after {
  content: "\e915";
  color: var(--red) !important;
}
.color.oda-c-female-circle-w:before {
  content: "\e916";
  color: var(--yellow) !important;
}
.color.oda-c-female-circle-w:after {
  content: "\e917";
  color: var(--red) !important;
}
.oda-c-female-circle-y .path1:before {
  content: "\e91b";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.15;
}
.color.oda-c-female-circle-y:before {
  content: "\e91c";
  color: var(--yellow) !important;
}
.color.oda-c-female-circle-y:after {
  content: "\e91d";
  color: var(--red) !important;
}
.color.oda-c-takeaway-circle-y:before {
  content: "\e921";
  color: var(--yellow) !important;
}
.color.oda-c-takeaway-circle-y:after {
  content: "\e922";
  color: var(--red) !important;
}
.color.oda-c-lunch-circle:before {
  content: "\e923";
  opacity: 0.1;
}
.color.oda-c-lunch-circle:after {
  content: "\e924";
}
.color.oda-c-male-circle-w:before {
  content: "\e925";
  color: var(--yellow) !important;
}
.color.oda-c-male-circle-w:after {
  content: "\e92c";
}
.oda-c-male-circle-y .path1:before {
  content: "\e92d";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.15;
}
.color.oda-c-male-circle-y:before {
  content: "\e932";
  color: var(--yellow) !important;
}
.color.oda-c-male-circle-y:after {
  content: "\e933";
}
.color.oda-c-minus-circle:before {
  content: "\e934";
  opacity: 0.1;
}
.color.oda-c-minus-circle:after {
  content: "\e935";
}
.color.oda-c-minus-circle-y:before {
  content: "\e938";
  color: var(--yellow) !important;
}
.color.oda-c-minus-circle-y:after {
  content: "\e939";
}
.color.oda-c-plus-circle:before {
  content: "\e93d";
  opacity: 0.1;
}
.color.oda-c-plus-circle:after {
  content: "\e93e";
}
.oda-c-trash-circle .path1:before {
  content: "\e93f";
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-trash-circle:before {
  content: "\e942";
  opacity: 0.5;
}
.color.oda-c-trash-circle:after {
  content: "\e943";
}
.oda-c-timer .path1:before {
  content: "\e905";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-timer:before {
  content: "\e9ce";
}
.color.oda-c-timer:after {
  content: "\e9cf";
}
.color.oda-c-utensil:before {
  content: "\e906";
}
.oda-c-utensil .path1:before {
  content: "\e907";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-utensil:after {
  content: "\e908";
}
.oda-c-utensils-alt-2 .path1:before {
  content: "\e92f";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-utensils-alt-2:before {
  content: "\e930";
}
.color.oda-c-utensils-alt-2:after {
  content: "\e931";
}
.oda-c-hotmeal .path1:before {
  content: "\e90c";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-hotmeal:before {
  content: "\e90d";
}
.color.oda-c-hotmeal:after {
  content: "\e90e";
}
.oda-c-noodle-dish .path1:before {
  content: "\e912";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-noodle-dish:before {
  content: "\e913";
}
.color.oda-c-noodle-dish:after {
  content: "\e914";
}
.oda-c-dish .path1:before {
  content: "\e918";
  color: var(--yellow);
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-dish:before {
  content: "\e919";
}
.color.oda-c-dish:after {
  content: "\e91a";
}
.oda-c-menu-bell .path1:before {
  content: "\e91e";
  color: var(--yellow);
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-menu-bell:before {
  content: "\e91f";
}
.color.oda-c-menu-bell:after {
  content: "\e920";
  opacity: 1 !important;
}
.oda-c-dish-timer .path1:before {
  content: "\e926";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.color.oda-c-dish-timer:before {
  content: "\e927";
}
.color.oda-c-dish-timer:after {
  content: "\e928";
}
.oda-c-takeaway .path1:before {
  content: "\e929";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.color.oda-c-takeaway:before {
  content: "\e92a";
}
.color.oda-c-takeaway:after {
  content: "\e92b";
}
.oda-c-delivery .path1:before {
  content: "\e92e";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.color.oda-c-delivery:after {
  content: "\e936";
}
.color.oda-c-delivery:before {
  content: "\e937";
}
.oda-c-utensils .path1:before {
  content: "\e93a";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.color.oda-c-utensils:after {
  content: "\e93b";
}
.color.oda-c-utensils:before {
  content: "\e93c";
}
.oda-c-burger-x .path1:before {
  content: "\e947";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-burger-x:before {
  content: "\e948";
}
.color.oda-c-burger-x:after {
  content: "\e94f";
}
.oda-c-search-logo .path1:before {
  content: "\e952";
  color: var(--yellow) !important;
  position: absolute;
  top: 0;
  left: 0;
}
.color.oda-c-search-logo:after {
  content: "\e957";
  opacity: 0.5;
}
.color.oda-c-search-logo:before {
  content: "\e958";
}

/* DUOTONE ICONS */
.duotone.oda-d-served:before {
  content: "\eaa7";
}
.duotone.oda-d-served:after {
  content: "\eaa8";
}
.duotone.oda-d-logo:after {
  content: "\e904";
}
.duotone.oda-d-logo:before {
  content: "\e9d0";
}
.duotone.oda-d-briefcase:after {
  content: "\e940";
}
.duotone.oda-d-briefcase:before {
  content: "\e941";
}
.duotone.oda-d-cart:after {
  content: "\e945";
}
.duotone.oda-d-cart:before {
  content: "\e946";
}
.duotone.oda-d-chat:after {
  content: "\e949";
}
.duotone.oda-d-chat:before {
  content: "\e94a";
}
.duotone.oda-d-dish:before {
  content: "\e94b";
}
.duotone.oda-d-dish:after {
  content: "\e94c";
}
.duotone.oda-d-edit:after {
  content: "\e94d";
}
.duotone.oda-d-edit:before {
  content: "\e94e";
}
.duotone.oda-d-favchannel:after {
  content: "\e950";
}
.duotone.oda-d-favchannel:before {
  content: "\e951";
}
.duotone.oda-d-firetag:after {
  content: "\e953";
}
.duotone.oda-d-firetag:before {
  content: "\e954";
}
.duotone.oda-d-foodtimer:after {
  content: "\e955";
}
.duotone.oda-d-foodtimer:before {
  content: "\e956";
}
.duotone.oda-d-gift:before {
  content: "\e95a";
}
.duotone.oda-d-gift:after {
  content: "\e95b";
}
.duotone.oda-d-home:before {
  content: "\e95c";
}
.duotone.oda-d-home:after {
  content: "\e95d";
}
.duotone.oda-d-pictures:after {
  content: "\e95e";
}
.duotone.oda-d-pictures:before {
  content: "\e95f";
}
.duotone.oda-d-star:after {
  content: "\e962";
}
.duotone.oda-d-star:before {
  content: "\e963";
}
.duotone.oda-d-switch:before {
  content: "\e964";
}
.duotone.oda-d-switch:after {
  content: "\e965";
}
.duotone.oda-d-trash:before {
  content: "\e966";
}
.duotone.oda-d-trash:after {
  content: "\e967";
}
.duotone.oda-d-user-setting:after {
  content: "\e968";
}
.duotone.oda-d-user-setting:before {
  content: "\e969";
}
.duotone.oda-d-link:after {
  content: "\eab5";
}
.duotone.oda-d-link:before {
  content: "\eab6";
  /* margin-left: -1em; */
  opacity: 0.5;
}

/* MONO ICONS */
.oda-reserved-occasion:before {
  content: "\e9d1";
}
.oda-store-announce:before {
  content: "\e9d2";
}
.oda-reserved:before {
  content: "\e9d3";
}
.oda-tnc:before {
  content: "\e9d4";
}
.oda-ar-vr:before {
  content: "\eab7";
}
.oda-link:before {
  content: "\eab8";
}
.oda-prep-min:before {
  content: "\eab9";
}
.oda-icon-3d:before {
  content: "\ea92";
}
.oda-membership-point:before {
  content: "\eaaf";
}
.oda-membership-stamp:before {
  content: "\eab0";
}
.oda-present:before {
  content: "\eaa9";
}
.oda-store-discount:before {
  content: "\eaaa";
}
.oda-qr-takeaway:before {
  content: "\e900";
}
.oda-table-dinein:before {
  content: "\e901";
}
.oda-number-pax:before {
  content: "\e902";
}
.oda-qr-dinein:before {
  content: "\e903";
}
.oda-download:before {
  content: "\e9cb";
}
.oda-ticket:before {
  content: "\e9cc";
}
.oda-refund:before {
  content: "\e959";
}
.oda-twitter:before {
  content: "\e944";
}
.oda-alert:before {
  content: "\e96c";
}
.oda-alert-alt:before {
  content: "\e96d";
}
.oda-bag:before {
  content: "\e96e";
}
.oda-bars:before {
  content: "\e96f";
}
.oda-bell:before {
  content: "\e970";
}
.oda-bill:before {
  content: "\e971";
}
.oda-bill-none:before {
  content: "\e972";
}
.oda-cake:before {
  content: "\e973";
}
.oda-calendar:before {
  content: "\e974";
}
.oda-card-info:before {
  content: "\e975";
}
.oda-channel:before {
  content: "\e976";
}
.oda-chat-options:before {
  content: "\e977";
}
.oda-check:before {
  content: "\e978";
}
.oda-check-alt:before {
  content: "\e979";
}
.oda-check-circle:before {
  content: "\e97a";
}
.oda-chef:before {
  content: "\e97b";
}
.oda-chevron-down:before {
  content: "\e97c";
}
.oda-chevron-left:before {
  content: "\e97d";
}
.oda-chevron-right:before {
  content: "\e97e";
}
.oda-chevron-up:before {
  content: "\e97f";
}
.oda-chevron-down-circle:before {
  content: "\e980";
}
.oda-chevron-left-circle:before {
  content: "\e981";
}
.oda-chevron-right-circle:before {
  content: "\e982";
}
.oda-chevron-up-circle:before {
  content: "\e983";
}
.oda-clock:before {
  content: "\e984";
}
.oda-clock-alt:before {
  content: "\e985";
}
.oda-copy:before {
  content: "\e986";
}
.oda-credit-card:before {
  content: "\e987";
}
.oda-delivery:before {
  content: "\e988";
}
.oda-dinein:before {
  content: "\e989";
}
.oda-discount:before {
  content: "\e98a";
}
.oda-dots:before {
  content: "\e98b";
}
.oda-eye-open:before {
  content: "\e98c";
}
.oda-eye-shut:before {
  content: "\e98d";
}
.oda-facebook:before {
  content: "\e98e";
}
.oda-faq:before {
  content: "\e98f";
}
.oda-filter:before {
  content: "\e990";
}
.oda-fire:before {
  content: "\e991";
}
.oda-fire-alt:before {
  content: "\e992";
}
.oda-flashlight:before {
  content: "\e993";
}
.oda-gift:before {
  content: "\e994";
}
.oda-heart:before {
  content: "\e995";
}
.oda-heart-solid:before {
  content: "\e996";
}
.oda-home:before {
  content: "\e997";
}
.oda-info:before {
  content: "\e998";
}
.oda-info_1:before {
  content: "\e999";
}
.oda-instagram:before {
  content: "\e99a";
}
.oda-keyboard-back-btn:before {
  content: "\e99b";
}
.oda-language:before {
  content: "\e99c";
}
.oda-list:before {
  content: "\e99d";
}
.oda-loading:before {
  content: "\e99e";
}
.oda-mail:before {
  content: "\e99f";
}
.oda-minus:before {
  content: "\e9a0";
}
.oda-mobile:before {
  content: "\e9a1";
}
.oda-mobile-reload:before {
  content: "\e9a2";
}
.oda-pass-lock:before {
  content: "\e9a3";
}
.oda-pass-lock-ok:before {
  content: "\e9a4";
}
.oda-phone:before {
  content: "\e9a5";
}
.oda-pin:before {
  content: "\e9a6";
}
.oda-plus:before {
  content: "\e9a7";
}
.oda-plus-circle:before {
  content: "\e9a8";
}
.oda-pointer:before {
  content: "\e9a9";
}
.oda-pot:before {
  content: "\e9aa";
}
.oda-qrcode:before {
  content: "\e9ab";
}
.oda-reload:before {
  content: "\e9ac";
}
.oda-remove-circle:before {
  content: "\e9ad";
}
.oda-scan:before {
  content: "\e9ae";
}
.oda-search:before {
  content: "\e9af";
}
.oda-settings:before {
  content: "\e9b0";
}
.oda-shield:before {
  content: "\e9b1";
}
.oda-shield-check:before {
  content: "\e9b2";
}
.oda-shield-lock:before {
  content: "\e9b3";
}
.oda-shield-lock-alt:before {
  content: "\e9b4";
}
.oda-shop:before {
  content: "\e9b5";
}
.oda-sign-out:before {
  content: "\e9b6";
}
.oda-sort:before {
  content: "\e9b7";
}
.oda-star:before {
  content: "\e9b8";
}
.oda-switch-user:before {
  content: "\e9b9";
}
.oda-thumb-down:before {
  content: "\e9ba";
}
.oda-thumb-up:before {
  content: "\e9bb";
}
.oda-times:before {
  content: "\e9bc";
}
.oda-upload:before {
  content: "\e9bd";
}
.oda-user-card:before {
  content: "\e9be";
}
.oda-user-open:before {
  content: "\e9bf";
}
.oda-utensils:before {
  content: "\e9c0";
}
.oda-utensils-alt:before {
  content: "\e9c1";
}
.oda-wallet:before {
  content: "\e9c2";
}
.oda-switch:before {
  content: "\e9d5";
}

/* COLOR SETTING */
[class^="oda-c-"].color,
[class*=" oda-c-"].color {
  position: relative;
}

[class^="oda-c-"].color:before,
[class*=" oda-c-"].color:before {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--primary);
}

[class^="oda-c-"].color:after,
[class*=" oda-c-"].color:after {
  opacity: 0.35;
  color: var(--primary);
}

/* DUOTONE SETTING */
[class^="oda-d-"].duotone,
[class*=" oda-d-"].duotone {
  position: relative;
}

[class^="oda-d-"].duotone:before,
[class*=" oda-d-"].duotone:before {
  position: absolute;
  left: 0;
  top: 0;
}

[class^="oda-d-"].duotone:after,
[class*=" oda-d-"].duotone:after {
  opacity: 0.35;
}

/* UTILITIES */
.icon-xs {
  font-size: 0.5em;
}

.icon-sm {
  font-size: 0.75em;
}

.icon-md {
  font-size: 1.25em;
}

.icon-lg {
  font-size: 1.5em;
}

.icon-1x {
  font-size: 1em;
}

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-4x {
  font-size: 4em;
}

.icon-5x {
  font-size: 5em;
}

.icon-6x {
  font-size: 6em;
}

.icon-7x {
  font-size: 7em;
}

.icon-8x {
  font-size: 8em;
}

.icon-9x {
  font-size: 9em;
}

.icon-10x {
  font-size: 10em;
}

.icon-fw {
  text-align: center;
  width: 1.25em;
}

.icon-ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

.icon-ul > li {
  position: relative;
  line-height: 2em;
}

.icon-ul > li .icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-border {
  border: solid 0.08em #f1f1f1;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.icon-pull-left {
  float: left;
}

.icon-pull-right {
  float: right;
}

.icon.icon-pull-left {
  margin-right: 0.3em;
}

.icon.icon-pull-right {
  margin-left: 0.3em;
}

/* ANIMATIONS */
.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
  display: inline-block;
}

.icon-pulse {
  -webkit-animation: icon-spin 1s infinite steps(8);
  animation: icon-spin 1s infinite steps(8);
  display: inline-block;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icon-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icon-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icon-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icon-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icon-flip-horizontal.icon-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  -webkit-filter: none;
  filter: none;
  display: inline-block;
}

.icon-inverse {
  color: #fff;
}
