@font-face {
  font-family: 'OverpassHeadlines';
  src: url('/assets/font-family/Overpass-Lotus-Headlines.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.lotuscar-theme {
  --font-style-primary: overpass-variable;
  --font-style-secondary: OverpassHeadlines;

  --background-primary: #000000;
  --background-secondary: #222222;
  --desktop-background-primary: #111111;

  --primary-color: #FFF200;

  --iconButtonText-primary: #000000;
  --iconTextButton-primary:#FFF200;
  --iconButtonText-secondary: #000000;
  --iconButtonText-tertiary: #FFF200;
  --iconButtonText-quaternary: #FFFFFF;
  --iconTextButton-tertiary: #FFF200;
  --iconButtonText-quinary:#FFF200;

  --card-color-primary: #222222;
  --card-border-primary: #222222;
  --card-color-secondary: #000000;
  --card-color-tertiary : #222222;
  --card-color-quaternary : #000000;
  --card-color-quinary : #222222;
  --dialog-color-primary: #222222;
  --dialog-color-secondary: #000000;

  --inputbox-primary: #222222;
  --inputbox-outline-primary: #FFF200;
  --inputbox-outline-secondary: #3C3C3C;;
  --inputbox-text-primary :#FFFFFF;

  --text-primary: #000000;
  --text-secondary: #FFFFFF;
  --text-tertiary: #FFFFFF;
  --text-quaternary : #FFFFFF;
  --text-font-weight-primary: normal;

  //general button + text
  --button-primary: #FFF200;
  --button-text-primary: #000000;

  --button-secondary: #000000;
  --button-text-secondary: #FFF200;

  --button-tertiary:#FFF200;
  --button-text-tertiary: #000000;

  --store-modif-plus-button-primary: #FFF200;
  --store-modif-plus-icon-primary: #000000;
  --store-modif-minus-icon-primary: #FFF200;

  --backbutton: #FFFFFF;

//store
  --order-type-primary: #FFF200;
  --order-type-secondary: #8E8E8E;
  --order-type-text-primary: #000000;
  --store-price-primary: #FFF200;
  --item-card-border-primary: #3C3C3C;
  --date-time-border-primary: #222222;

  --selected-primary: #fff200ad;
  --selected-secondary: #FFF200;
  --selected-tertiary: #FFF200;
  --selected-quaternary: #FFF200;
  --outline-primary: #FFF200;
  --odr-selected-primary: #FFF200;

  //cart
  --cart-card-primary: #FFF200;
  --order-item-indicator: #4F4F4F;
  --order-item-icon-indicator: #000000;
  --cart-price-primary: #FFF200;
  --cart-order-info-background: #000000;

  //dine-in
  --selected-orderType: #FFF200;

  --icon-background : rgb(255 255 255 / 5%);

  --border-outline: #ffffff00;
  --cart-header : #4F4F4F;
  --cart-outline-primary: #3C3C3C;
  --cart-text-primary: #000000;
  --unselected-cart-outline: #4F4F4F;

  --selected-menu-primary: rgb(255 255 255 / 6%);

  --payment-background-primary: #000000;

  --dropdownMenu-currentTab-primary: #FFF200;
  --current-section-tag-primary: #FFF200;

  --item-card-outline-primary: #222222;

  --order-summary-header-primary: #FFF200;

  --order-amount-card: rgba(255, 255, 255, 0.1);

  --store-info-detail-primary: #FFFFFF;
  --disable-section : #3C3C3C;
  --scrollup-icon : #FFF200;
}
